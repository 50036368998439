import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://9d225ffe46b4a7453610f427ad09f655@o1241374.ingest.us.sentry.io/4508410576633856",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});